import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleTemplate.css';

const Article3 = () => {
    return (
        <div className="article-template">

            <main className="article-template-content">
                <br /><br /><br /><br />
                <h1 className="article-template-title">-3点 → -3点 黙れ</h1>
                <p className="article-template-date">2024/06/25</p>
                <br /><br /><br /><br />
                <br /><br />            <p>2023年の春からですかね。</p>
                <br /><br />          <p>「情報処理安全確保支援士」っていう、情報系の資格の最難関みたいなポジションの試験があるのですが、僕は密かにこの資格が欲しくてお勉強をしていました。（しています。）</p>
                <br /><br />          <p>この資格は年に２回しか受験することができなくて、毎年4月と10月に開催されています。当時（2023/4）の僕は、まあ半年くらい猛勉強すれば受かるだろう。と考えて勉強していました。</p>
                <br /><br />          <p>初受験は2023年の秋。500時間くらい勉強しましたが、受かる気は全くしませんでした。過去問を解いていても合格基準の半分すら取れない、みたいなレベルだったので。</p>
                <br /><br />          <p>セキュリティの資格なのですが、セキュアな技術を未経験のまま受験する人はほとんどおらず、受験者の平均年齢も30歳くらいのイカれた試験だったので、半ば諦めながら受験に挑みました。</p>
                <br /><br />          <p>試験は３科目（午前I・午前Ⅱ・午後）の全てを60%以上の割合で正解すれば合格、というものです。</p>
                <br /><br />          <p>その中でも最難関は午後。正直、午後以外の試験は下位資格の応用情報と同じくらいのレベルなのです。</p>
                <br /><br />          <p>結果は、午前I：60点、午前II：68点、午後：57点でした。</p>
                <br /><br />           <p>えっと、黙れ。</p>
                <br /><br />           <p>午後試験で3点落ちしてしまったのです。</p>
                <br /><br />           <p>当時の僕は全く受かる気がしていなかったので、不合格なのは納得だったんですが、意外と得点が良くてイラッとしました。</p>
                <br /><br />           <p>そこで僕は諦めず、もう半年勉強を続けることにしました。</p>
                <br /><br />           <p>そして半年後（2024年春）。</p>
                <br /><br />            <p>今回の試験は難易度が爆上がりし、技術未経験の学生には相当厳しい難易度でした。</p>
                <br /><br />            <p>結果が発表される前から、残念ながら合格はほぼ無理だろうと思っていました。</p>
                <br /><br />            <p>そして、結果発表。</p>
                <br /><br />            <p>結果は、午前I：免除、午前II：78点、午後：57点でした。</p>
                <br /><br />           <p>マジで黙れ。</p>
                <br /><br />            <p>もうなんか、57点しか取れないんじゃないかとすら思ってます。僕は元々、素数なので57という数字が大好きだったんですが、この一連で嫌いになりました。</p>
                <br /><br />            <p>今現在（2024/9）に記事を書いていますが、来月に３回目の受験を控えています。もうギャンブルだと思ってるので、何も勉強していません。</p>
                <br /><br />            <p>たぶん57点なんでしょうね。それでは。</p>

            </main>

            <footer className="article-template-footer">
                <Link to="/blog" className="article-template-back-to-blog">Back to Blog List</Link>
            </footer>
            <br /><br />
        </div>
    );
};

export default Article3;