import React from 'react'
import mireiImage from "../Images/mireshien.jpg";

const Page01 = () => {
    return (
        <div className="text-center">
            <br /><br /><br /><br /><br /><br />
            <h2 style={{ letterSpacing: '4px', fontWeight: '380' }}>R6 情報処理安全確保支援士を受験して</h2><br></br>
            <img src={mireiImage} alt="" />
            <p class="service">
                まだ作成中です・・
            </p>
        </div>
    )
}

export default Page01;