import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleTemplate.css';

const Article2 = () => {
    return (
        <div className="article-template">

            <main className="article-template-content">
                <br /><br /><br /><br />
                <h1 className="article-template-title">競プロとかいうマジでおもんないゲーム</h1>
                <p className="article-template-date">2023/10/08</p>
                <br /><br /><br /><br />
                <p>競プロって知ってますか？</p>
                <br /><br />
                <p>（Atcoder公式サイトより）</p>
                <br /><br />
                <p>決められた条件のもとで与えられた問題、課題をプログラミングを用いて解決し、その過程や結果を競うものを競技プログラミングといいます。</p>
                <br /><br />
                <p>様々なジャンルの出題がされますが、プログラミングや思考力、数学力、知識を活用します。</p>
                <br /><br />
                <p>そのうち、アルゴリズムやヒューリスティックというジャンルについてはAtCoderやCodeforces等で定期的にコンテストが開かれており、その結果によってレーティングと呼ばれる実力指数が変化します。</p>
                <br /><br />
                <p>コンテストの開始時間に問題が一斉に公開されます。それまでに出されたことのない問題が出題され、どのように解決するかが問われます。</p>
                <br /><br />
                <p>---</p>
                <br /><br />
                <p>僕は日本で1番規模の大きいAtCoderというコンテスト（弊大生なら知ってる人も多いんじゃないかな）に毎週参加しているわけなのですが、これを始めて1年弱、ずっと思っていることがあります。</p>
                <br /><br />
                <p>マジで面白くないです。</p>
                <br /><br />
                <p>全然問題が解けるようにならないし、大学で学んだプログラミングなんてほとんど役立ちません。（AtCoderのレベルが高すぎて）</p>
                <br /><br />
                <p>Xで有名な競プロerはたくさんいるのですが、みんな強いひとばっかりで、心を折られた競プロerも多いんじゃないでしょうか。</p>
                <br /><br />
                <p>弊学生でも「AtCoder始めてみた！」みたいなことを呟いた1ヶ月後にはアカウント自体消してる、みたいな人をたくさん見てきました。</p>
                <br /><br />
                <p>ここまで記事を見てくれた人にはただのAtCoderアンチのように見えていると思うし、なんで続けてんの？みたいに感じる人も多いと思います。</p>
                <br /><br />
                <p>これだけ面白くないとゴネている僕が競プロを続けている理由は、「これ以上にメリットがたくさんあるから」なのです。</p>
                <br /><br />
                <p>ここからは競プロをベタ褒めするフェーズに突入しますので、競プロを始めてみたい！という人はここからの記事を見てください。（遅い）</p>
                <br /><br />
                <p>まず、競プロのメリットを列挙します。</p>
                <br /><br />
                <p>就活に強い</p>
                <br /><br />
                <p>競プロは、エンジニア職で就活をしよう、と考えている人にはめちゃくちゃおすすめです。コンテストで培われるアルゴリズム実装能力及び思考力は、現場でも活用される部分が多いです。実際、僕も就活で精一杯アピールしてきました。</p>
                {/* <br></br>
                <p>はじめまして</p>
                <br></br>
                <p>おそらくこの記事を見てくれている人は、僕のことを深く知ってくれていると思うので、自己紹介は伏せておきます。</p>
                <br></br>
                <p>今日は、僕がポートフォリオサイトを作ろうとしたきっかけについて少しお話ししようと思います。</p>
                <br></br>
                <p>まず、僕は大学の中でも「情報デザインコース」というコースに所属していて、そのコースの学生の大半はポートフォリオ（自分の制作した成果物の記録のこと。以下：PF）を制作する風潮がありました。</p>
                <br></br>
                <p>少なからず僕もPFを制作していたのですが、作ってみると自分のセンスの無さに絶望したのです。今まで見てきたPFは先輩のものや、芸大に通う学生のもので、とても一朝一夕で仕上げることのできるクオリティではありませんでした。</p>
                <br></br>
                <p>なので、「PFじゃなくて、もっと僕にできる表現方法はないか」と考えていたら、僕の通う大学の学生がWebサイトでポートフォリオを作っていたのです。</p>
                <br></br>
                <p>元々エンジニア職を志望していた僕は、デザインソフトでPFを作るよりもこちらの方がアピールできそう！と思いました。</p>
                <br></br>
                <p>また、情報系の大学生である自分にはもっと適合しているとも感じます。実際、1年生の時に自分のWebサイトを制作する授業もありました。</p>
                <br></br>
                <p>こんな感じで自分のWebサイトを制作し始めたのですが、実はこのWebPFは既に5回ほど作り直していて、一般に向けて公開したのは現在のWebサイトが初めてなのです。</p>
                <br></br>
                <p>フロントサイドの言語もだんだん変化してきており、html直書き→html/css→react/jsとなって今に至ります。個人のWebでもreactがめっちゃおすすめですね。</p>
                <br></br>
                <p>と、初めての記事はだいぶ堅い感じで書きましたので、こちらを見てくれている企業様はこの記事だけでブラウザバックしていただけると感謝です。</p>
                <br></br>
                <p>それではまた〜</p>
                <br></br> */}
            </main>

            <footer className="article-template-footer">
                <Link to="/blog" className="article-template-back-to-blog">Back to Blog List</Link>
            </footer>
            <br /><br />
        </div>
    );
};

export default Article2;