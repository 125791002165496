import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleTemplate.css';

const Article4 = () => {
    return (
        <div className="article-template">

            <main className="article-template-content">
                <br /><br /><br /><br />
                <h1 className="article-template-title">重症ドルオタ、親に呆れられる</h1>
                <p className="article-template-date">2023/10/08</p>
                <br /><br /><br /><br />
                <p>全員ヒロイン=LOVE!!!</p>
                <br /><br />
                <p>アイドルに毎日を支えられているみなさんこんにちは</p>
                <br /><br />
                <p>またまた碌でもない記事を書きます。</p>
                <br /><br />
                <p>みなさんアイドルは好きですか？好きですよね。</p>
                <br /><br />
                <p>僕は卒業研究でもアイドルを研究しちゃうくらい大好きなんですよ。</p>
                <br /><br />
                <p>男の人の嫌な趣味ランキング第１位（ぼく調べ）に君臨しているアイドルオタクですが、そんなこと知ったこっちゃないです。自分に正直でいるべきだと思います。</p>
                <br /><br />
                <p>僕のアイドルオタクの歴史を辿ると長くなってしまうので一旦、個人的に最近アツいアイドルでも紹介させてください。</p>
                <br /><br />
                <p>- 透色ドロップ</p>
                <br /><br />
                <p>いわゆる「地下アイドル」と呼ばれるカテゴリに分類されるアイドルグループですが、今めちゃくちゃアツいです。楽曲のクオリティ、歌声含めパフォーマンス含め地上アイドルに匹敵するレベルと言っても過言じゃないです。</p>
                <br /><br />
                <p>特に好きな曲は「最愛」です。ここにリンク貼りますので、１回見てきなさい。</p>
                <br /><br />
                <p>- ≒JOY</p>
                <br /><br />
                <p>あの指原莉乃がプロデュースするアイドルです。このグループは、顔がめちゃくちゃ可愛い！！アイドルに顔を求めるな！派閥もそこそこいると思いますが、黙ってください。ビジュアルも戦闘力の一部なのです。</p>
                <br /><br />
                <p>特に好きな曲は「夢見る♡アイドル」です。香乃愛ちゃんと結婚しようと思ってます。</p>
                <br /><br />
                <p>- iLiFE!</p>
                <br /><br />
                <p>知ってる人も多いんじゃないかな！</p>
                <br /><br />
                <p>こちらも地下アイドルですが、もはや地上レベルで集客できちゃうアイドルです。運営が神・ファンサも神・全員元気で可愛い！そんなグループです。</p>
                <br /><br />
                <p>今1番ライブに行きたいアイドルです。上京したら死ぬほど行こうと思ってます。推せるうちに推そう。</p>
                <br /><br />
                <p>特に好きな曲は「のびしろグリッター」です。りりたんもぐもぐ。</p>
                <br /><br />
                <p>北海道にいるうちは、現場に行くってめっちゃ大変なんです。交通費がチケット代の10倍とか当たり前ですし、大学生の僕が頻繁にライブに行くことなんて到底できません。</p>
                <br /><br />
                <p>そこで賢い僕は、東京で就活をすることにしました。こんな不純な動機のやつでも意外とうまく行くものなのです。</p>
                {/* <p>全員ヒロイン=LOVE!!!</p>

                <p>（Atcoder公式サイトより）</p>

                <p>決められた条件のもとで与えられた問題、課題をプログラミングを用いて解決し、その過程や結果を競うものを競技プログラミングといいます。</p>

                <p>様々なジャンルの出題がされますが、プログラミングや思考力、数学力、知識を活用します。</p>

                <p>そのうち、アルゴリズムやヒューリスティックというジャンルについてはAtCoderやCodeforces等で定期的にコンテストが開かれており、その結果によってレーティングと呼ばれる実力指数が変化します。</p>

                <p>コンテストの開始時間に問題が一斉に公開されます。それまでに出されたことのない問題が出題され、どのように解決するかが問われます。</p>

                <p>---</p>

                <p>僕は日本で1番規模の大きいAtCoderというコンテスト（弊大生なら知ってる人も多いんじゃないかな）に毎週参加しているわけなのですが、これを始めて1年弱、ずっと思っていることがあります。</p>

                <p>マジで面白くないです。</p>

                <p>全然問題が解けるようにならないし、大学で学んだプログラミングなんてほとんど役立ちません。（AtCoderのレベルが高すぎて）</p>

                <p>Xで有名な競プロerはたくさんいるのですが、みんな強いひとばっかりで、心を折られた競プロerも多いんじゃないでしょうか。</p>

                <p>弊学生でも「AtCoder始めてみた！」みたいなことを呟いた1ヶ月後にはアカウント自体消してる、みたいな人をたくさん見てきました。</p>

                <p>ここまで記事を見てくれた人にはただのAtCoderアンチのように見えていると思うし、なんで続けてんの？みたいに感じる人も多いと思います。</p>

                <p>これだけ面白くないとゴネている僕が競プロを続けている理由は、「これ以上にメリットがたくさんあるから」なのです。</p>

                <p>ここからは競プロをベタ褒めするフェーズに突入しますので、競プロを始めてみたい！という人はここからの記事を見てください。（遅い）</p>

                <p>まず、競プロのメリットを列挙します。</p>

                <p>就活に強い</p>

                <p>競プロは、エンジニア職で就活をしよう、と考えている人にはめちゃくちゃおすすめです。コンテストで培われるアルゴリズム実装能力及び思考力は、現場でも活用される部分が多いです。実際、僕も就活で精一杯アピールしてきました。</p> */}
                {/* <br></br>
                <p>はじめまして</p>
                <br></br>
                <p>おそらくこの記事を見てくれている人は、僕のことを深く知ってくれていると思うので、自己紹介は伏せておきます。</p>
                <br></br>
                <p>今日は、僕がポートフォリオサイトを作ろうとしたきっかけについて少しお話ししようと思います。</p>
                <br></br>
                <p>まず、僕は大学の中でも「情報デザインコース」というコースに所属していて、そのコースの学生の大半はポートフォリオ（自分の制作した成果物の記録のこと。以下：PF）を制作する風潮がありました。</p>
                <br></br>
                <p>少なからず僕もPFを制作していたのですが、作ってみると自分のセンスの無さに絶望したのです。今まで見てきたPFは先輩のものや、芸大に通う学生のもので、とても一朝一夕で仕上げることのできるクオリティではありませんでした。</p>
                <br></br>
                <p>なので、「PFじゃなくて、もっと僕にできる表現方法はないか」と考えていたら、僕の通う大学の学生がWebサイトでポートフォリオを作っていたのです。</p>
                <br></br>
                <p>元々エンジニア職を志望していた僕は、デザインソフトでPFを作るよりもこちらの方がアピールできそう！と思いました。</p>
                <br></br>
                <p>また、情報系の大学生である自分にはもっと適合しているとも感じます。実際、1年生の時に自分のWebサイトを制作する授業もありました。</p>
                <br></br>
                <p>こんな感じで自分のWebサイトを制作し始めたのですが、実はこのWebPFは既に5回ほど作り直していて、一般に向けて公開したのは現在のWebサイトが初めてなのです。</p>
                <br></br>
                <p>フロントサイドの言語もだんだん変化してきており、html直書き→html/css→react/jsとなって今に至ります。個人のWebでもreactがめっちゃおすすめですね。</p>
                <br></br>
                <p>と、初めての記事はだいぶ堅い感じで書きましたので、こちらを見てくれている企業様はこの記事だけでブラウザバックしていただけると感謝です。</p>
                <br></br>
                <p>それではまた〜</p>
                <br></br> */}
            </main>

            <footer className="article-template-footer">
                <Link to="/blog" className="article-template-back-to-blog">Back to Blog List</Link>
            </footer>
            <br /><br />
        </div>
    );
};

export default Article4;